// @ts-nocheck
import React, { useEffect, useState } from "react";
import Joi from "joi-browser";

import AdmissionSection from "../common/admissionCommonComponents/admissionSection";
import MultiItemGenerator from "../common/admissionCommonComponents/multipleItemGenerator";
import { CreateResAdmission } from "../../services/residentService";
import { getLegalobject } from "../../utils/legalCasesObject";
import { getAdmissionobject } from "../../utils/admissionObject";
import { prepAdmissionData } from "../../utils/prepAdmissionData";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getList } from "../../services/listService";
import TopBar from "../../components/common/topBar";
import auth from "../../services/authService";
import { getUsers } from "../../services/users/users_admin";
import { level3Access } from "../../utils/roles";

const CreateAdmission = () => {
  const ResID = window.location.pathname.split("/")[3];
  const [activeSession, setActiiveSession] = useState("admission");
  const [reload, setReload] = useState(false);
  const [data, setData] = useState(getAdmissionobject());
  const sessions = [
    { name: "admission", label: "Admission" },
    { name: "legal", label: "Legal Cases" },
    { name: "success", label: "Submitted Successfully!" },
  ];
  const [formData, setFormData] = useState({
    admission: {
      RecentPhase: "0",
    },
    legal: [],
  });
  const [user, setUser] = useState({
    firstName: "",
    email: "",
    lastName: "",
  });

  useEffect(() => {
    const user = auth.getCurrentUser();
    setUser(user);
  }, []);

  const [admittedFromList, setAdmittedFromList] = useState([]);
  const [admittedFromOther, setAdmittedFromOther] = useState(false);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [users, setUsers] = useState([]);
  function dateFormatter(d) {
    if (d) return d.slice(0, 10);
  }
  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }
  // Optionally, you can update the state with props.data if it exists
  React.useEffect(() => {
    async function getData() {
      let AdmittedFromList = await getList(8);
      let MaritalStatusList = await getList(5);
      setAdmittedFromList(AdmittedFromList);
      setMaritalStatusList(MaritalStatusList);
    }
    getData();
  }, []);

  const isEditing = true;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    if (name == "AdmittedFrom" && value == "Other") {
      setAdmittedFromOther(true);
      setFormData((prevData) => ({
        admission: {
          ...prevData.admission,
          [name]: "",
        },
        legal: prevData.legal,
      }));
    } else if (name == "BlockFromReentry" || name == "LeftVolunteerly") {
      setFormData((prevData) => ({
        admission: {
          ...prevData.admission,
          [name]: value === "false" ? false : true,
        },
        legal: prevData.legal,
      }));
    } else if (value === "") {
      console.log(name, value);
      setFormData((prevData) => ({
        admission: {
          ...prevData.admission,
          [name]: null,
        },
        legal: prevData.legal,
      }));
    } else {
      setFormData((prevData) => ({
        admission: {
          ...prevData.admission,
          [name]: value,
        },
        legal: prevData.legal,
      }));
    }
    // setAdmittedFromOther(false)
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      admission: {
        ...prevData.admission,
        [name]: checked,
      },
      legal: prevData.legal,
    }));
  };

  const formatDate = (date) => {
    if (date) return date.slice(0, 10);
  };

  const phaseDataObject = {
    type: "select",
    size: "grow1",
    name: "admission_1_2_RecentPhase",
    label: "Phase",
    options: [
      // { _id: "0", name: "0", value: "0" },
      { _id: "1", name: "1", value: "1" },
      { _id: "2", name: "2", value: "2" },
      { _id: "3", name: "3", value: "3" },
      { _id: "4", name: "4", value: "4" },
      { _id: "5", name: "5", value: "5" },
    ],
    schema: Joi.string().max(30).required(),
  };

  const handleChange = (item) => {
    console.log(item.name);
    let updatedData = [...data];
    let updatedFormData = { ...formData };
    if (item.name === "ProgramInDate") {
      console.log("1");
      if (item.value) {
        if (!data[1][2]) {
          console.log("2");
          updatedData[1][2] = phaseDataObject;
        }
      } else {
        updatedFormData.admission.Phase = undefined;
      }
    }
    setFormData((prevData) => ({
      admission: {
        ...prevData.admission,
        [item.name]: item.value,
      },
      legal: prevData.legal,
    }));
    setData(updatedData);
  };

  //   const setmultiItems = (items, section) => {
  //     let updatedFormData = { ...formData };
  //     updatedFormData[section] = items;
  //     setFormData(updatedFormData);
  //   };

  //====================================== Validations ========================================

  const validate = () => {
    const options = { abortEarly: false };
    let schema = {};
    let validationData = {};
    let data1 = [...data];
    // data.forEach((row) => {
    //   row.forEach((item) => {
    //     schema[item.label] = item.schema;
    //     validationData[item.label] = item.value;
    //   });
    // });

    // const { error } = Joi.validate(validationData, schema, options);
    // if (!error) {
    //   console.log("1");
    //   if (data[0][1].value) {
    //     console.log("2");
    //     console.log(data[1][2]);
    //     if (!data[1][2].value) {
    //       console.log("3");
    //       data1[1][2].error = "Phase required for Program In date";
    //       setData(data1);
    //       return false;
    //     }
    //   }
    //   return true;
    // }

    // data.forEach((row, rowI) => {
    //   row.forEach((item, itemI) => {
    //     error.details.forEach((er) => {
    //       if (er.path[0] === item.label) {
    //         data1[rowI][itemI].error = er.message;
    //       }
    //     });
    //   });
    // });
    // setData(data1);
    return true;
  };

  const validateProperty = ({ name, value, schema, label }) => {
    // const obj = { [name.split("_")[3]]: value };
    // const schema1 = { [name.split("_")[3]]: schema };
    const obj = { [label]: value };
    const schema1 = { [label]: schema };
    const { error } = Joi.validate(obj, schema1);
    return error ? error.details[0].message : null;
  };

  //====================================== Session Switches ========================================

  const nextSession = async (e) => {
    console.log("eun");
    if (activeSession === "admission") {
      //   if (await validate()) {
      setActiiveSession("legal");
      //   }
    }
  };

  const previousSession = () => {
    let f;
    sessions.forEach((session, i) => {
      if (session.name === activeSession) {
        if (!f) {
          f = true;
          setActiiveSession(sessions[i - 1].name);
        }
      }
    });
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    setActiiveSession("submitting");
    console.log(formData);
    let prepedData = prepAdmissionData(formData, ResID);
    console.log(prepedData);
    let res = await CreateResAdmission(prepedData);
    console.log(res);
    if (res?.status === 200) {
      toast.success("Resident Admitted Successfully!");
      setActiiveSession("success");
    }
    if (res.status === 202) {
      toast.error(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  };

  const setmultiItems = (items, section) => {
    let updatedFormData = { ...formData };
    updatedFormData[section] = items;
    setFormData(updatedFormData);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let { data, error } = await getUsers();
        console.log(data);
        if (error) return setUsers([]);
        data = data.filter(
          (user1) => !user1.isAdmin && !user1.isProductionSupport
        );
        setUsers(data);
      } catch (error) {
        setUsers([]);
      }
    }
    fetchData();
  }, []);

  // let categoryIndex = 1;
  // let currentSession = sessions.filter((session, i) => {
  //   if (session.name === activeSession) {
  //     categoryIndex = i + 1;
  //     return true;
  //   }
  //   return false;
  // })[0];

  if (!ResID) return <Redirect to="/dashboard" />;
  if (activeSession === "success")
    return <Redirect to={`/dashboard/resident/${ResID}`} />;

  return (
    <>
      <TopBar user={user} />
      <div className="w-full">
        <div className="p-8 bg-background self-center flex flex-col">
          <div className="createResident-Container-headSection m-4 ms-0 font-bold">
            <h2 className="primary">Create Admission</h2>
          </div>
          {activeSession === "submitting" && (
            <div className="Submitting-message">
              <h1 className="display-2">Submitting Admission</h1>
              <h3 className="primary">Do not refresh the page!</h3>
            </div>
          )}
          {activeSession === "success" && (
            <>
              <div className="Submitting-message">
                <h1 className="display-1">Congratulations!</h1>
                <h3 className="">
                  New admission has been created successfully
                </h3>
              </div>
            </>
          )}
          {activeSession === "admission" && (
            <>
              <form
                className="bg-white shadow-lg rounded-lg p-6 w-full"
                onSubmit={doSubmit}
              >
                <div className="grid grid-cols-2 gap-6 pb-4">
                  <div className="col-span-2 flex justify-between items-center border-b-2 border-t-0 border-x-0 border-gray-400 border-dashed pb-4 mb-6">
                    <div className="text-black font-bold text-[24px] me-6 self-center">
                      {localStorage.getItem("resName")}
                    </div>
                    <div className="flex flex-row max-w-sm">
                      <span>
                        Guest In Date:{" "}
                        {!isEditing ? (
                          <strong className="text-teal-600 font-bold text-md">
                            {formData.admission.GuestInDate
                              ? dateShower(formData.admission.GuestInDate)
                              : null}
                          </strong>
                        ) : (
                          !reload && (
                            <div className="flex items-center">
                              <input
                                type="date"
                                name="GuestInDate"
                                value={
                                  dateFormatter(
                                    formData.admission?.GuestInDate
                                  ) || ""
                                }
                                className="ms-2"
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                required
                              />
                              <button
                                type="button"
                                className="ml-2"
                                onClick={() => {
                                  setFormData((prevData) => ({
                                    admission: {
                                      ...prevData.admission,
                                      GuestInDate: null,
                                    },
                                    legal: prevData.legal,
                                  }));
                                  setReload(true);
                                  setTimeout(() => {
                                    setReload(false);
                                  }, 1);
                                }}
                              >
                                X
                              </button>
                            </div>
                          )
                        )}
                      </span>
                    </div>
                    <div>
                      <span>
                        Program In Date:{" "}
                        {!isEditing ? (
                          <strong className="text-teal-600 font-bold text-md">
                            {formData.admission.ProgramInDate
                              ? dateShower(formData.admission.ProgramInDate)
                              : null}
                          </strong>
                        ) : (
                          !reload && (
                            <div className="flex items-center">
                              <input
                                type="date"
                                name="ProgramInDate"
                                value={
                                  dateFormatter(
                                    formData.admission?.ProgramInDate
                                  ) || ""
                                }
                                className="ms-2"
                                onChange={handleInputChange}
                                disabled={!isEditing}
                              />
                              <button
                                type="button"
                                className="ml-2"
                                onClick={() => {
                                  setFormData((prevData) => ({
                                    admission: {
                                      ...prevData.admission,
                                      ProgramInDate: null,
                                      RecentPhase: "0",
                                    },
                                    legal: prevData.legal,
                                  }));
                                  setReload(true);
                                  setTimeout(() => {
                                    setReload(false);
                                  }, 1);
                                }}
                              >
                                X
                              </button>
                            </div>
                          )
                        )}
                      </span>
                    </div>
                    <div>
                      <>
                        {level3Access(user) && !user.isFinance && (
                          <button
                            type="submit"
                            className="bg-teal-600 text-white px-4 py-2 rounded mr-2"
                          >
                            Submit
                          </button>
                        )}
                        {/* <button
                        className="bg-teal-600 text-white px-4 py-2 rounded"
                        // onClick={() => }
                      >
                        Cancel
                      </button> */}
                      </>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-6 pb-4 mb-6">
                  <div>
                    <label className="block text-secondary">
                      Case Worker Name
                    </label>
                    <select
                      name="CaseWorkerName"
                      value={formData.admission.CaseWorkerName || ""}
                      className="w-full border rounded px-2 py-1"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      <option value={""}></option>
                      {users.map((user) => (
                        <option value={user.firstName + " " + user.lastName}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-secondary">
                      Intake Coordinator Name
                    </label>
                    <select
                      name="IntakeCoordinatorName"
                      value={formData.admission.IntakeCoordinatorName || ""}
                      className="w-full border rounded px-2 py-1"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      required
                    >
                      <option value={""}></option>
                      {users.map((user) => (
                        <option value={user.firstName + " " + user.lastName}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-secondary">
                      Admitted from
                    </label>
                    {admittedFromOther ||
                    (formData.admission?.AdmittedFrom &&
                      !admittedFromList.some(
                        (adm) => adm.name == formData.admission?.AdmittedFrom
                      )) ? (
                      <div className="relative w-full">
                        <input
                          type="text"
                          name="AdmittedFrom"
                          maxLength={15}
                          value={formData.admission.AdmittedFrom || ""}
                          className="w-full border rounded px-2 py-1"
                          onChange={handleInputChange}
                          disabled={!isEditing}
                          placeholder="Enter text..."
                        />
                        {isEditing ? (
                          <svg
                            onClick={() => {
                              if (isEditing) {
                                setFormData((prevData) => ({
                                  admission: {
                                    ...prevData.admission,
                                    AdmittedFrom: "",
                                  },
                                  legal: prevData.legal,
                                }));
                                setAdmittedFromOther(false);
                              }
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                          >
                            <path
                              fill="currentColor"
                              d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z"
                            />
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <select
                        className="w-full border rounded px-2 py-1"
                        name="AdmittedFrom"
                        value={formData.admission.AdmittedFrom || ""}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      >
                        <option value="" hidden>
                          Select from Drop Down
                        </option>
                        {admittedFromList.map((adm) => (
                          <option value={adm?.name}>{adm?.name}</option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div>
                    <label className="block text-secondary">
                      Est Move Out Date
                    </label>
                    {!reload && (
                      <div className="flex items-center">
                        <input
                          type="date"
                          name="EstMoveOutDate"
                          value={
                            dateFormatter(formData.admission?.EstMoveOutDate) ||
                            ""
                          }
                          className="ms-2 border"
                          onChange={handleInputChange}
                          disabled={!isEditing}
                        />
                        <button
                          type="button"
                          className="ml-2"
                          onClick={() => {
                            setFormData((prevData) => ({
                              admission: {
                                ...prevData.admission,
                                EstMoveOutDate: null,
                              },
                              legal: prevData.legal,
                            }));
                            setReload(true);
                            setTimeout(() => {
                              setReload(false);
                            }, 1);
                          }}
                        >
                          X
                        </button>
                      </div>
                    )}
                  </div>
                  {formData.admission?.ProgramInDate && (
                    <div>
                      <label className="block text-secondary">Phase</label>
                      <select
                        className="w-full border rounded px-2 py-1"
                        name="RecentPhase"
                        value={formData.admission.RecentPhase || ""}
                        onChange={handleInputChange}
                        required={formData.admission.ProgramInDate}
                      >
                        <option value="" hidden>
                          Select from Drop Down
                        </option>
                        {phaseDataObject.options.map((phase) => (
                          <option value={phase?.value}>{phase?.value}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div>
                      <h3 className="text-xl font-bold mb-4 text-teal-600 border-b pb-2">
                        Previous Record
                      </h3>
                      <div className="grid grid-cols-2 gap-6 mb-6">
                        <div>
                          <label className="block text-secondary">
                            Treatment Completed Previously
                          </label>
                          <input
                            type="text"
                            name="TimesCompletedTreatment"
                            value={
                              formData.admission.TimesCompletedTreatment || ""
                            }
                            className="w-full border rounded px-2 py-1"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                        </div>
                        <div>
                          <label className="block text-secondary">
                            Previous Inpatient Attempts
                          </label>
                          <input
                            type="text"
                            name="PreviousInpatientAttempts"
                            value={
                              formData.admission.PreviousInpatientAttempts || ""
                            }
                            className="w-full border rounded px-2 py-1"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-4 text-teal-600 border-b pb-2">
                        Referrals
                      </h3>
                      <div className="grid grid-cols-2 gap-6 mb-6">
                        <div>
                          <label className="block text-secondary">
                            Who Referred
                          </label>
                          <input
                            type="text"
                            name="WhoReferred"
                            value={formData.admission.WhoReferred || ""}
                            className="w-full border rounded px-2 py-1"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                        </div>
                        <div>
                          <label className="block text-secondary">
                            Who Referred - Relationship
                          </label>
                          <input
                            type="text"
                            name="WhoReferredRelationship"
                            value={
                              formData.admission.WhoReferredRelationship || ""
                            }
                            className="w-full border rounded px-2 py-1"
                            onChange={handleInputChange}
                            disabled={!isEditing}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-xl font-bold mb-4 text-teal-600 border-b pb-2">
                      Additional Information
                    </h3>
                    <div className="grid grid-cols-2 gap-6 mb-6">
                      <div>
                        <label className="block text-secondary">
                          Church Name
                        </label>
                        <input
                          type="text"
                          name="ChurchName"
                          value={formData.admission.ChurchName || ""}
                          className="w-full border rounded px-2 py-1"
                          onChange={handleInputChange}
                          disabled={!isEditing}
                        />
                      </div>
                      <div>
                        <label className="block text-secondary">
                          Mentor Name
                        </label>
                        <input
                          type="text"
                          name="MentorName"
                          value={formData.admission.MentorName || ""}
                          className="w-full border rounded px-2 py-1"
                          onChange={handleInputChange}
                          disabled={!isEditing}
                        />
                      </div>
                      <div>
                        <label className="block text-secondary">
                          Last County
                        </label>
                        <input
                          type="text"
                          name="LastCounty"
                          value={formData.admission.LastCounty || ""}
                          className="w-full border rounded px-2 py-1"
                          onChange={handleInputChange}
                          disabled={!isEditing}
                        />
                      </div>
                      <div className="">
                        <label className="block text-secondary">
                          Marital Status
                        </label>
                        <select
                          className="w-full border rounded px-2 py-1"
                          disabled={!isEditing}
                          value={formData.admission.MaritalStatus || null}
                          name="MaritalStatus"
                          onChange={handleInputChange}
                        >
                          <option value={null} hidden>
                            Select
                          </option>
                          {maritalStatusList.map((ms) => (
                            <option value={ms?.name}>{ms?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-4 mt-4">
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="HasChildren"
                          checked={formData.admission.HasChildren || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Has Children
                      </label>
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="WasHomeless"
                          checked={formData.admission.WasHomeless || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Was Homeless
                      </label>
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="WasJobless"
                          checked={formData.admission.WasJobless || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Employed
                      </label>
                    </div>
                    <p className="mt-4 pt-2 pb-0 mb-0 text-gray-500">
                      Conditions
                    </p>
                    <div className="grid grid-cols-3 gap-4 mt-2 pb-0">
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="OnMA"
                          checked={formData.admission.OnMA || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        On MA
                      </label>
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="OnSSI"
                          checked={formData.admission.OnSSI || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        On SSI/SSD
                      </label>
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="HasMentalHealthChallanges"
                          checked={
                            formData.admission.HasMentalHealthChallanges ||
                            false
                          }
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Mental Health Challenges
                      </label>
                    </div>
                    <p className="mt-4 pb-0 mb-0 text-gray-500">Program</p>
                    <div className="grid grid-cols-3 gap-4 mt-2">
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="CanSelfSignout"
                          checked={formData.admission.CanSelfSignout || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Can Self sign-out
                      </label>
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="IsApprovedPartner"
                          checked={
                            formData.admission.IsApprovedPartner || false
                          }
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Approved Partner
                      </label>
                      <label className="text-teal-600 flex items-center">
                        <input
                          type="checkbox"
                          name="IsRestricted"
                          checked={formData.admission.IsRestricted || false}
                          className="mr-2"
                          onChange={handleCheckboxChange}
                          disabled={!isEditing}
                        />
                        Restricted
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
          {activeSession === "legal" && (
            <>
              <div className="createResident-Container-headSection">
                <h2 className="primary">{`Legal Cases`}</h2>
              </div>
              {/* @ts-ignore */}
              <MultiItemGenerator
                data={formData.legal}
                setData={setmultiItems}
                sectionName={"legal"}
                sectionModel={getLegalobject()}
                toPreviousSection={previousSession}
                submitWholeForm={doSubmit}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateAdmission;
