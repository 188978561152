import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const NotesList = (props) => {
  const notes = props.data;
  notes?.sort((a, b) => new Date(b.NoteDateTime) - new Date(a.NoteDateTime));

  const [NoteCategories, setNoteCategories] = useState();

  useEffect(() => {
    const asyncFunc = async () => {
      const noteList = props.noteSubjects;
      console.log(noteList);
      notes?.forEach((note, i) => {
        noteList?.forEach((list) => {
          if (list.value === note.NoteCategoryListID) {
            notes[i].category = list.name;
          }
        });
      });
      setNoteCategories(noteList);
    };
    asyncFunc();
  }, [props.noteSubjects]);

  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }
  return (
    <div className="notesList-Container">
      {notes?.length > 0 ? (
        <div className="notesList-List">
          {notes.map((notes) => (
            <div className="noteList-Item" key={notes.ID}>
              <div className="noteList-Item-Title">
                {notes.NoteSubject ? notes.NoteSubject : "Note"}
              </div>
              <div className="noteList-Item-Date">
                <div>{NoteCategories && ` ${notes.category}`}</div>
                <div>
                  <div>{dateShower(notes.NoteDateTime)}</div>
                  {notes.NoteByName && (
                    <div className="noteList-Item-Date-container">
                      {notes.NoteByName}
                    </div>
                  )}
                </div>
              </div>
              <div className="noteList-Item-Body">{notes.Note}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="notesList-NoData">No Notes</div>
      )}
    </div>
  );
};

export default NotesList;
