import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaPen, FaTrash } from "react-icons/fa";
import * as pdfjs from "pdfjs-dist";
import {
  createServiceAgreement,
  deleteServiceAgreement,
  getAllServiceAgreement,
  updateServiceAgreement,
} from "../../services/serviceAgreementService";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

function dateFormatter(d) {
  if (d) return d.slice(0, 10);
}

const ServiceAgreementLetters = ({ ResID, level4Access }) => {
  const [salList, setSalList] = useState([]);
  async function fetchData() {
    const sal = await getAllServiceAgreement(ResID);
    sal?.data?.map((sal) => {
      sal.StartDate = dateFormatter(sal.StartDate);
      sal.EndDate = dateFormatter(sal.EndDate);
    });
    setSalList(sal.data);
  }
  useEffect(() => {
    if (ResID) fetchData();
  }, [ResID]);

  // State to manage the visibility of the form and editing
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  // Form state for adding/editing a SAL
  const [newSAL, setNewSAL] = useState({
    ID: "",
    SALNumber: "",
    ResID: "",
    StartDate: "",
    EndDate: "",
    Rate: "",
  });

  // Handle input change for the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSAL({ ...newSAL, [name]: value });
  };

  const [pdfFile, setPdfFile] = useState(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      console.log("PDF file selected:", file);
      setPdfFile(file);
      extractFormattedText(file);
    } else {
      alert("Please upload a valid PDF file.");
    }
    event.target.value = ""; // Reset the input
  };

  const extractFormattedText = async (file) => {
    setLoading(true);
    try {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const pdfData = new Uint8Array(fileReader.result);
        const pdf = await pdfjs.getDocument({ data: pdfData }).promise;

        console.log("PDF loaded:", pdf);
        let formattedText = "";

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();

          const pageText = formatTextWithPositions(textContent.items);
          formattedText += `Page ${i}:\n${pageText}\n\n`;
        }

        setText(formattedText);
        function extractFields(text) {
          const result = {};

          // Extract SALNumber (Service Agreement Number)
          const salNumberMatch = text.match(/SERVICE AGREEMENT#.*?(\d{11})/);
          result.SALNumber = salNumberMatch ? salNumberMatch[1] : "";

          // Extract StartDate
          const startDateMatch = text.match(
            /Start Date:\s+(\d{2}\/\d{2}\/\d{2})/
          );
          result.StartDate = startDateMatch
            ? formatDate(startDateMatch[1])
            : "";

          // Extract EndDate
          const endDateMatch = text.match(/End Date:\s+(\d{2}\/\d{2}\/\d{2})/);
          result.EndDate = endDateMatch ? formatDate(endDateMatch[1]) : "";

          // Extract Rate
          const rateMatch = text.match(/Rate\/Unit:\s+\$(\d+\.\d{2})/);
          result.Rate = rateMatch ? rateMatch[1] : "";
          result.ResID = ResID;
          return result;
        }

        // Utility function to format date from MM/DD/YY to YYYY-MM-DD
        function formatDate(date) {
          const [month, day, year] = date.split("/");
          const fullYear = year.length === 2 ? `20${year}` : year; // Handle YY to YYYY conversion
          return `${fullYear}-${month}-${day}`;
        }

        const extractedData = extractFields(formattedText);
        addSAL(extractedData);
      };

      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
      alert("An error occurred while processing the PDF. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formatTextWithPositions = (items) => {
    const lines = [];
    let currentLine = [];
    let currentLineY = null;

    items.forEach((item, index) => {
      const text = item.str;
      const [, , x, y] = item.transform; // Extract positional data
      const roundedY = Math.round(y); // Round Y to handle slight variations

      if (currentLineY === null || Math.abs(currentLineY - roundedY) <= 5) {
        // Continue the current line
        currentLine.push({ text, x });
        currentLineY = roundedY;
      } else {
        // Push the completed line
        const sortedLine = currentLine.sort((a, b) => a.x - b.x); // Sort by X for proper order
        lines.push(sortedLine.map((word) => word.text).join(" "));
        currentLine = [{ text, x }];
        currentLineY = roundedY;
      }

      // Handle the last line
      if (index === items.length - 1 && currentLine.length > 0) {
        const sortedLine = currentLine.sort((a, b) => a.x - b.x);
        lines.push(sortedLine.map((word) => word.text).join(" "));
      }
    });
    console.log(lines);

    return lines.join("\n");
  };

  // Add a new SAL to the list
  const addSAL = async (newSal) => {
    if (isEditing && editingIndex !== null) {
      const res = await updateServiceAgreement(newSAL);
      if (res.status === 200) {
        fetchData();
        toast.success("Service Agreement updated successfully");
      } else {
        toast.error("Failed to update Service Agreement");
      }
      setIsEditing(false);
      setEditingIndex(null);
    } else {
      const newServiceAgreement = newSal || {
        ...newSAL,
        ResID,
      };
      let res = await createServiceAgreement(newServiceAgreement);
      if (res.status === 201) {
        let ID = res?.data?.ID;
        setSalList([
          ...salList,
          {
            ID,
            SALNumber: newServiceAgreement.SALNumber,
            ResID,
            StartDate: newServiceAgreement.StartDate,
            EndDate: newServiceAgreement.EndDate,
            Rate: newServiceAgreement.Rate,
          },
        ]);
        toast.success("Service Agreement created successfully");
        // setSalList([newServiceAgreement, ...salList]);
      } else {
        toast.error("Failed to create Service Agreement");
      }
    }
    setNewSAL({
      ID: "",
      SALNumber: "",
      ResID: "",
      StartDate: "",
      EndDate: "",
      Rate: "",
    });
    setShowForm(false);
  };

  // Handle the "Edit" button click
  const editSAL = (id) => {
    const salToEdit = salList.find((sal) => sal.ID === id);
    console.log(salToEdit);
    setNewSAL(salToEdit);
    setIsEditing(true);
    setEditingIndex(salList.findIndex((sal) => sal.ID === id));
    setShowForm(true);
  };

  const deleteSAL = async (id) => {
    const res = await deleteServiceAgreement(id);
    if (res.status === 200) {
      toast.success("Service Agreement deleted successfully");
      const updatedSALs = salList.filter((sal) => sal.ID !== id);
      setSalList(updatedSALs);
    } else {
      toast.error("Failed to delete Service Agreement");
    }
  };

  // Cancel adding or editing a SAL
  const cancelAddSAL = () => {
    setNewSAL({
      ID: "",
      SALNumber: "",
      ResID: "",
      StartDate: "",
      EndDate: "",
      Rate: "",
    });
    setIsEditing(false);
    setShowForm(false);
  };

  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }

  const getStatus = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    const daysUntilEnd = (end - today) / (1000 * 60 * 60 * 24);

    if (start > today) {
      return "Upcoming";
    } else if (end < today) {
      return "Expired";
    } else if (daysUntilEnd <= 30 && daysUntilEnd > 0) {
      return "Expiring";
    } else {
      return "Current";
    }
  };

  const getRowClass = (startDate, endDate) => {
    const status = getStatus(startDate, endDate);
    return status === "Current" || status === "Expiring"
      ? "bg-teal-600 text-white border-t border-gray-200"
      : "border-t border-gray-200";
  };

  return (
    <div>
      <div className="residentView-sectionBox-header">
        <h3 className="primary font-medium">Service Agreement Letters</h3>
        {level4Access ? (
          showForm == false ? (
            <div>
              <label
                htmlFor="file-upload"
                className="bg-teal-600 text-white rounded-md cursor-pointer border-none h-12 px-4  pb-2 pt-2.5"
              >
                Upload New SAL
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileUpload}
                accept="application/pdf"
                className="hidden"
              />
            </div>
          ) : (
            <button
              className="b blackButton"
              onClick={() => {
                cancelAddSAL();
                setShowForm(false);
              }}
            >
              Cancel
            </button>
          )
        ) : (
          <></>
        )}
      </div>

      <div className="p-1"></div>
      {!showForm && (
        <div className="max-h-[230px] overflow-auto pt-2">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  SAL
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Rate
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Start Date
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  End Date
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Status
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {salList
                .sort((a, b) => new Date(b.EndDate) - new Date(a.EndDate))
                .map((sal) => (
                  <tr
                    key={sal.ID}
                    className={`${getRowClass(sal.StartDate, sal.EndDate)}`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap">
                      {sal.SALNumber}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">{sal.Rate}</td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {dateShower(sal.StartDate)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {dateShower(sal.EndDate)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {getStatus(sal.StartDate, sal.EndDate)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <button
                        onClick={() => editSAL(sal.ID)}
                        className="mr-4 text-gray-400 bg-transparent"
                      >
                        <FaPen />
                      </button>
                      <button
                        onClick={() => deleteSAL(sal.ID)}
                        className="text-gray-700 bg-transparent"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {showForm && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addSAL();
          }}
        >
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-teal-700">SAL Number</label>
              <input
                type="text"
                name="SALNumber"
                placeholder="SAL Number"
                value={newSAL.SALNumber}
                onChange={handleInputChange}
                className="border p-2 rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-teal-700">Rate</label>
              <input
                type="text"
                name="Rate"
                placeholder="Rate"
                value={newSAL.Rate}
                onChange={(e) => {
                  const value = e.target.value;
                  // Regular expression to allow numbers with up to two decimal places
                  if (/^\d*\.?\d{0,2}$/.test(value)) {
                    handleInputChange(e); // Only update if input matches regex
                  }
                }}
                className="border p-2 rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-teal-700">Start Date</label>
              <input
                type="date"
                name="StartDate"
                value={newSAL.StartDate}
                onChange={handleInputChange}
                className="border p-2 rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-teal-700">End Date</label>
              <input
                type="date"
                name="EndDate"
                value={newSAL.EndDate}
                onChange={handleInputChange}
                className="border p-2 rounded w-full"
                required
              />
            </div>
          </div>
          {/* Save Button */}
          <div className="text-center">
            <button
              type="submit"
              className="b py-2.5 px-4 w-auto rounded mt-6 "
            >
              {isEditing ? "Save" : "Add"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ServiceAgreementLetters;
