import React, { useEffect, useState } from "react";

const ToggleList = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [props?.data?.length]);

  // Handle selection of individual items
  const handleSelect = (entry) => {
    if (selectedItems.includes(entry.ResID)) {
      setSelectedItems(selectedItems.filter((id) => id !== entry.ResID));
    } else {
      setSelectedItems([...selectedItems, entry.ResID]);
    }
  };

  // Handle "Select All" functionality
  const handleSelectAll = () => {
    if (selectedItems.length === props.data.length) {
      setSelectedItems([]); // Deselect all if everything is already selected
    } else {
      setSelectedItems(props.data.map((entry) => entry.ResID)); // Select all
    }
  };

  // Handle multi-remove action
  const handleMultiRemove = () => {
    props.data
      .filter((entry) => selectedItems.includes(entry.ResID))
      .forEach((entry) => props.onRemove(entry));
    setSelectedItems([]); // Clear selections after removal
  };

  // Handle multi-toggle action
  const handleMultiToggle = () => {
    props.data
      .filter((entry) => selectedItems.includes(entry.ResID))
      .forEach((entry) => props.onToggle(entry));
  };

  // Check if all items are selected
  const areAllSelected = selectedItems.length === props?.data?.length;

  return (
    <div className="toggle-Container">
      {props.data?.length > 0 ? (
        <div className="toggle-List">
          {/* Select All / Deselect All button */}
          <div className="flex flex-row justify-between mx-4">
            <div>
              <button
                onClick={handleSelectAll}
                className="w-[6rem] text-nowrap"
              >
                {areAllSelected ? "Deselect All" : "Select All"}
              </button>
            </div>
            {selectedItems.length > 0 && (
              <>
                <div>
                  <i
                    className="fa fa-exchange cursor-pointer"
                    aria-hidden="true"
                    onClick={handleMultiToggle}
                  />
                </div>
                <div>
                  <i
                    className="fa fa-trash-o danger-text pe-2 cursor-pointer"
                    aria-hidden="true"
                    onClick={handleMultiRemove}
                  />
                </div>
              </>
            )}
          </div>

          {props.data.map((entry) => (
            <div
              className="fragmentList-Item paddingVertical backgroundWhite "
              key={entry.ResID}
            >
              {/* Checkbox for multi-select */}

              <div className="flex flex-row">
                <input
                  type="checkbox"
                  checked={selectedItems.includes(entry.ResID)}
                  onChange={() => handleSelect(entry)}
                />
                <div className="flex flex-row justify-between items-center">
                  <div className="ps-2 w-20 text-nowrap text-[18px] min-w-[200px]">
                    {entry.ResFirstName + " " + entry.ResLastName}
                  </div>

                  <div className="w-7">
                    <i
                      className="fa fa-exchange cursor-pointer"
                      aria-hidden="true"
                      onClick={() => props.onToggle(entry)}
                    />
                  </div>
                  <div className="">
                    <div className="text-xl text-teal-700">
                      {entry.CheckedInDate
                        ? new Date(entry.CheckedInDate).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : ""}
                    </div>
                    <div className="text-sm">
                      {entry.CheckedInDate
                        ? new Date(entry.CheckedInDate).toLocaleDateString(
                            "en-US",
                            {
                              month: "short", // Short month format (e.g., Nov)
                              day: "numeric", // Numeric day (e.g., 5)
                              year: "numeric", // Full year (e.g., 2024)
                            }
                          )
                        : ""}
                    </div>
                  </div>
                  <div className="">
                    <i
                      className="fa fa-trash-o danger-text cursor-pointer"
                      aria-hidden="true"
                      onClick={() => props.onRemove(entry)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="fragmentList-NoData">None</div>
      )}
    </div>
  );
};

export default ToggleList;
