import Joi from "joi-browser";
import { getStatesOfCountry } from "../services/dropdownLocationService";

export function getobject() {
  return {
    basic: [
      [
        {
          type: "input",
          size: "grow2",
          typeName: "text",
          name: "basic_0_0_SSN",
          label: "SSN (###-##-####)",
          value: undefined,
          schema: Joi.string()
            .required()
            .max(11)
            .regex(/^\d{3}-\d{2}-\d{4}$/)
            .error((errors) => {
              errors.forEach((err) => {
                console.log(err);
                switch (err.type) {
                  case "string.regex.base":
                    err.message = "Invalid format  - (###-##-###)";
                    break;
                  default:
                    break;
                }
              });
              return errors;
            }),
        },
        {
          type: "imagePicker",
          size: "grow2",
          name: "basic_0_1_ResPictureKey",
          label: "Profile Image",
          value: undefined,
          schema: Joi.string(),
        },
        // {
        //   type: "input",
        //   typeName: "text",
        //   size: "grow2",
        //   name: "basic_0_0_ResPictureKey",
        //   label: "Image Link",
        //   value: undefined,
        //   schema: Joi.string(),
        // },
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_0_1_IsVeteran",
        //   label: "Is Veteran?",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_0_2_IsChurchAttender",
        //   label: "Church Attender?",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_0_3_HasChildren",
        //   label: "Has Children?",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_1_0_ResFirstName",
          label: "Resident First Name",
          value: undefined,
          schema: Joi.string().required().max(30),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_1_1_ResMiddleName",
          label: "Resident Middle Name",
          value: undefined,
          schema: Joi.string().max(30),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_1_2_ResLastName",
          label: "Resident Last Name",
          value: undefined,
          schema: Joi.string().required().max(30),
        },
        {
          type: "date",
          size: "grow1",
          name: "basic_1_3_ResBirthDate",
          label: "Date of Birth",
          value: null,
          schema: Joi.date().required(),
        },
      ],
      [
        {
          type: "input",
          size: "grow2",
          typeName: "text",
          name: "basic_2_0_PSNumber",
          label: "PMI Number (########)",
          value: undefined,
          schema: Joi.string()
            .min(8)
            .max(8)
            .optional()
            .allow("")
            .error((errors) => {
              errors.forEach((err) => {
                console.log(err);
                switch (err.type) {
                  case "string.regex.base":
                    err.message = "Invalid format  - (########)";
                    break;
                  default:
                    break;
                }
              });
              return errors;
            }),
        },
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_2_2_IsPregnant",
        //   label: "Is Pregnant",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_3_0_ResEmailAddr",
          label: "Email Address",
          value: undefined,
          schema: Joi.string().email().max(60),
        },
        // {
        //   type: "select",
        //   size: "grow1",
        //   name: "basic_3_1_MaritalStatusListID",
        //   label: "Marital Status",
        //   options: [],
        //   value: undefined,
        //   schema: Joi.string(),
        // },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_4_0_ResPhoneNumber",
          label: "Phone number",
          value: undefined,
          schema: Joi.string().max(30),
        },
        // {
        //   type: "input",
        //   size: "grow1",
        //   typeName: "text",
        //   name: "basic_4_1_WhereRaised",
        //   label: "Last County of Residence",
        //   value: undefined,
        //   show: false,
        //   schema: Joi.string().max(100),
        // },
        {
          type: "select",
          size: "grow1",
          name: "basic_4_1_RaceListID",
          label: "Race",
          options: [],
          value: undefined,
          schema: Joi.string(),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_5_0_ResZipCode",
          label: "Resident Zip Code",
          value: undefined,
          schema: Joi.string().max(10),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_5_1_ResAddress1",
          label: "Resident Address",
          value: undefined,
          schema: Joi.string().max(50),
        },
        // {
        //   type: "select",
        //   size: "grow1",
        //   name: "basic_5_2_AdmittedFrom",
        //   label: "Admitted From",
        //   options: [],
        //   value: undefined,
        //   schema: Joi.string().max(100),
        // },
      ],
      [
        {
          type: "select",
          size: "grow1",
          name: "basic_6_0_ResState",
          label: "Resident State",
          options: [],
          value: undefined,
          schema: Joi.string().max(70),
        },
        {
          type: "select",
          size: "grow1",
          name: "basic_6_1_ResCity",
          label: "Resident City",
          options: [],
          value: undefined,
          schema: Joi.string().max(70),
        },
        {
          type: "input",
          size: "grow1",
          name: "basic_6_2_ResCity",
          label: "Other City",
          value: undefined,
          schema: Joi.string().max(70),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "basic_6_3_ResCounty",
          label: "Resident County",
          value: undefined,
          schema: Joi.string().max(70),
        },
      ],
      [
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_7_0_OnMA",
        //   label: "On MA",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_7_1_OnSSISSD",
        //   label: "On SSI/SSD",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
        // {
        //   type: "checkbox",
        //   size: "grow1",
        //   name: "basic_7_2_IsEmployed",
        //   label: "Is Employed",
        //   value: false,
        //   schema: Joi.boolean(),
        // },
      ],
    ],
    church: [
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "church_0_0_ChurchName",
          label: "Church Name",
          value: undefined,
          schema: Joi.string().max(30),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "church_0_1_ChurchLocation",
          label: "Church Location",
          value: undefined,
          schema: Joi.string().max(30),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "church_1_0_ChurchPhone",
          label: "Church Phone",
          value: undefined,
          schema: Joi.string().max(15),
        },
      ],
    ],
    contact: [
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_0_0_ContactFirstName",
          label: "First Name",
          value: undefined,
          schema: Joi.string().max(30),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_0_1_ContactLastName",
          label: "Last Name",
          value: undefined,
          schema: Joi.string().max(30),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_1_0_Email",
          label: "Email",
          value: undefined,
          schema: Joi.string().email().max(60),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_1_1_Phone",
          label: "Phone Number",
          value: undefined,
          schema: Joi.string().max(15),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_2_0_Address",
          label: "Address",
          value: undefined,
          schema: Joi.string().max(50),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_2_1_EmergencyRelationship",
          label: "Emergency Relationship",
          value: undefined,
          schema: Joi.string().max(30),
        },
      ],
      [
        {
          type: "select",
          size: "grow1",
          name: "contact_3_0_State",
          label: "State",
          options: getStatesOfCountry("United States"),
          // options: [],
          value: undefined,
          schema: Joi.string().max(30),
        },
        {
          type: "input",
          size: "grow1",
          name: "contact_3_1_City",
          label: "City",
          options: [],
          value: undefined,
          schema: Joi.string().max(30),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "contact_3_2_ZipCode",
          label: "Zip Code",
          value: undefined,
          schema: Joi.string().max(10),
        },
      ],
    ],
    notes: [
      [
        {
          type: "select",
          size: "grow1",
          name: "notes_0_0_NoteCategoryListID",
          label: "Category",
          options: [],
          value: undefined,
          schema: Joi.string().max(30),
        },
        // {
        //   type: "input",
        //   size: "grow1",
        //   typeName: "text",
        //   name: "notes_0_1_NoteByName",
        //   label: "Note By Name",
        //   value: undefined,
        //   schema: Joi.string(),
        // },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "notes_1_0_NoteSubject",
          label: "Subject",
          value: undefined,
          schema: Joi.string().max(40),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "notes_2_0_Note",
          label: "Note",
          value: undefined,
          schema: Joi.string(),
        },
      ],
    ],
    employment: [
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "employment_0_0_JobTitle",
          label: "Job Title",
          value: undefined,
          schema: Joi.string().max(30),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "employment_0_1_Industry",
          label: "Industry",
          value: undefined,
          schema: Joi.string().max(30),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "employment_1_0_Skill",
          label: "Skill",
          value: undefined,
          schema: Joi.string().max(50),
        },
        {
          type: "input",
          size: "grow1",
          typeName: "text",
          name: "employment_1_1_BusinessName",
          label: "BusinessName",
          value: undefined,
          schema: Joi.string().max(50),
        },
      ],
      [
        {
          type: "input",
          size: "grow1",
          typeName: "number",
          name: "employment_2_0_WorkHours",
          label: "Work Hours",
          value: undefined,
          schema: Joi.number(),
        },
      ],
    ],
  };
}
