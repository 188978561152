import React from "react";

const Date1 = ({ name, label, error, value, onChange, ...rest }) => {
  // Handle the change event to pass the date value to the parent component
  const handleChange = (event) => {
    const dateValue = event.target.value;
    onChange(dateValue);
  };
  const handleFocus = (e) => {
    const { target } = e;

    if (target) {
      target.readOnly = true; // -------> this for all others
      target.blur(); //  ------> this for ios iphone, TV Browsers, Ipad, Safari
    }
  };

  return (
    <div className={`${label === "Child DOB" ? "pb-4" : ""}`}>
      {label && <p className="mb-2 font-medium text-teal-600">{label}</p>}
      <div>
        <input
          type="date"
          id={name}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          // onFocus={handleFocus}
          name={name}
          value={value}
          onChange={handleChange}
          className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
            error
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-blue-500"
          }`}
          {...rest}
        />
      </div>
      {error && (
        <div className="mt-1 text-sm text-red-500">
          {label} must be a valid date
        </div>
      )}
    </div>
  );
};

export default Date1;
