import React from "react";
import InputFieldLayoutRow from "./inputFieldLayoutRow";
import Joi from "joi-browser";

const Form = ({
  onChange,
  data,
  submit,
  cancel,
  buttonLabel,
  secondaryActionLabel,
  secondaryAction,
  readOnly,
  isFragement,
  disabled,
  setFragmentState,
  setPhaseState,
  name,
  isResidentUpdate,
}) => {
  console.log(data);
  const formatNumber = (inputValue) => {
    let numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = "";

    for (let i = 0; i < numericValue.length; i++) {
      if (i === 3 || i === 5) {
        formattedValue += "-";
      }
      formattedValue += numericValue[i];
    }

    if (formattedValue.length > 11) {
      formattedValue = formattedValue.slice(0, 11);
    }
    return formattedValue;
  };

  const handleChange = (json, name) => {
    let itemName = name.split("_");

    let item = data[parseInt(itemName[1], 10)][parseInt(itemName[2], 10)];
    console.log(json, name);
    if (
      item.type === "input" ||
      item.type === "select" ||
      item.type === "textBox"
    ) {
      item.value =
        json.currentTarget.value === "" ? undefined : json.currentTarget.value;
    } else if (item.type === "checkbox") {
      item.value = json.target.checked;
    } else if (item.type === "date") {
      if (item.name == "phaseChange_0_1_TransitionDate") item.value = json;
      else item.value = json.currentTarget.value;
    } else if (item.type === "yesNo") {
      item.value = json;
    }
    if (itemName.includes("SSN")) {
      item.value = formatNumber(json.currentTarget.value);
    }
    if (itemName.includes("PSNumber")) {
      const formatNumber = (inputValue) => {
        let numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
        let formattedValue = "";

        for (let i = 0; i < numericValue.length; i++) {
          // if (i === 4) {
          //   formattedValue += "-";
          // }
          formattedValue += numericValue[i];
        }

        if (formattedValue.length > 8) {
          formattedValue = formattedValue.slice(0, 8);
        }
        return formattedValue;
      };
      item.value = formatNumber(json.currentTarget.value);
    }

    const errorMessage = validateProperty(item);
    if (errorMessage) item.error = errorMessage;
    else item.error = undefined;
    return onChange(itemName, item);
  };

  const doSubmit = () => {
    let result = validate();
    return submit(result);
  };

  //====================================== Validations ========================================

  const validate = () => {
    const options = { abortEarly: false };
    let schema = {};
    let validationData = {};
    let data1 = [...data];
    data1.forEach((row) => {
      row.forEach((item) => {
        schema[item.label] = item.schema;
        validationData[item.label] = item.value;
      });
    });
    console.log(validationData, schema, options);
    const { error } = Joi.validate(validationData, schema, options);
    if (!error) return { validation: true };

    data1.forEach((row, rowI) => {
      row.forEach((item, itemI) => {
        error.details.forEach((er) => {
          if (er.path[0] === item.label) {
            data1[rowI][itemI].error = er.message;
          }
        });
      });
    });
    return { validation: false, update: data1 };
  };

  const buttonValidation = () => {
    const options = { abortEarly: false };
    let schema = {};
    let validationData = {};
    let data1 = [...data];
    data1.forEach((row) => {
      row.forEach((item) => {
        schema[item.label] = item.schema;
        validationData[item.label] = item.value;
      });
    });

    const { error } = Joi.validate(validationData, schema, options);
    if (!error) return null;
    return true;
  };

  const validateProperty = ({ name, value, schema, label }) => {
    // const obj = { [name.split("_")[3]]: value };
    // const schema1 = { [name.split("_")[3]]: schema };
    const obj = { [label]: value };
    const schema1 = { [label]: schema };
    const { error } = Joi.validate(obj, schema1);
    return error ? error.details[0].message : null;
  };

  return (
    <div className={isResidentUpdate ? "" : `simpleForm-Container-formSection`}>
      {data.map((row, i) => (
        <InputFieldLayoutRow
          key={i.toString()}
          data={row}
          disabled={disabled}
          onChange={handleChange}
        />
      ))}
      <div
        className={
          isResidentUpdate ? "flex-end" : `simpleForm-Container-endSection`
        }
      >
        {secondaryAction && !readOnly && (
          <button
            className={
              isResidentUpdate
                ? "me-4 bg-transparent text-teal-700"
                : `b blackButton`
            }
            onClick={secondaryAction}
          >
            {secondaryActionLabel ? secondaryActionLabel : "Cancel"}
          </button>
        )}
        {isFragement && (
          <button
            className="b blackButton"
            onClick={() => setFragmentState(name, "View")}
          >
            Cancel
          </button>
        )}
        {submit && !readOnly && !cancel && (
          <button
            className={`b ${buttonValidation() && "fadedButton"}`}
            onClick={doSubmit}
            disabled={readOnly ? true : buttonValidation()}
          >
            {buttonLabel ? buttonLabel : "Submit"}
          </button>
        )}
        {submit && !readOnly && cancel && setPhaseState && (
          <>
            <button
              className={`b ${buttonValidation() && "fadedButton"}`}
              onClick={doSubmit}
              disabled={readOnly ? true : buttonValidation()}
            >
              {buttonLabel ? buttonLabel : "Submit"}
            </button>
            <button
              className={`b blackButton`}
              onClick={() => setPhaseState("View")}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
