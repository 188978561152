import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAllCenters } from "../../services/centerService";
import { toast } from "react-toastify";
import {
  setResidentAdmissionRecord,
  updateResident,
} from "../../services/residentService";
import { differenceInDays, parseISO } from "date-fns";
import { getUsers } from "../../services/users/users_admin";

function checkDate(date) {
  const inputDate = new Date(date);
  const now = new Date();
  const currentDay = now.getDay();
  const lastSunday = new Date(now);
  lastSunday.setDate(now.getDate() - currentDay);
  lastSunday.setHours(0, 0, 0, 0);
  return inputDate >= lastSunday && inputDate <= now;
}
function parseAnyDate(date) {
  const parsedDate = new Date(date);

  // If the date is already a Date object, return it directly
  if (date instanceof Date) {
    return date;
  }

  // If the date is an ISO string or a format recognized by the Date constructor
  else if (!isNaN(parsedDate)) {
    return parsedDate;
  }

  // Array of custom date formats to attempt parsing
  const formats = [
    "yyyy-MM-dd",
    "MM/dd/yyyy",
    "dd/MM/yyyy",
    "yyyy/MM/dd",
    "MM-dd-yyyy",
    "dd-MM-yyyy",
    "yyyy.MM.dd",
    "MM.dd.yyyy",
    "dd.MM.yyyy",
  ];

  // Attempt to parse the date using custom formats
  return parseISO(date);
}

function getDaysBetweenDates(date1, date2) {
  const parsedDate1 = parseAnyDate(date1);
  const parsedDate2 = parseAnyDate(date2);
  console.log(date1, date2, parsedDate1, parsedDate2);
  return Math.abs(differenceInDays(parsedDate2, parsedDate1));
}

const ResTopBar = (props) => {
  console.log(props);
  const [userCenter, setUserCenter] = useState("Dashboard");
  const location = useLocation();
  const [editMode, setEditMode] = useState({
    name: false,
    room: false,
    caseWorker: false,
  });
  const [users, setUsers] = useState([]);
  const [caseWorker, setCaseWorker] = useState(null);
  const [admission, setAdmission] = useState(null);
  const [phase, setPhase] = useState(null);
  const [days, setDays] = useState(null);
  const [admissions, setAdmissions] = useState([]);
  const [resident, setResident] = useState({
    ResFirstName: props.resident?.ResFirstName,
    ResMiddleName: props.resident?.ResMiddleName,
    ResLastName: props.resident?.ResLastName,
    RoomNum: props.resident?.RoomNum,
    isActive: props.resident?.IsActive,
    ResID: props.resident?.ResID,
    SSN: props.resident?.SSN,
    infractions: props?.infractions?.filter((inf) => checkDate(inf.Date)),
  });

  useEffect(() => {
    setResident({
      ResFirstName: props.resident?.ResFirstName,
      ResMiddleName: props.resident?.ResMiddleName,
      ResLastName: props.resident?.ResLastName,
      RoomNum: props.resident?.RoomNum,
      ResID: props.resident?.ResID,
      isActive: props.resident?.IsActive,
      SSN: props.resident?.SSN,
      infractions: props?.infractions?.filter((inf) => checkDate(inf.Date)),
    });
    console.log(props?.admissions);
    if (props?.resident?.IsActive) {
      let adm =
        props?.admissions?.filter(
          (ad) => ad?.AdmissionID == props?.resident?.RecentAdmissionID
        )?.length > 0
          ? props?.admissions?.filter(
              (ad) => ad?.AdmissionID == props?.resident?.RecentAdmissionID
            )[0]
          : null;
      if (adm) {
        setAdmission(adm);
        let days = getDaysBetweenDates(
          adm.GuestInDate,
          adm.DateOut ? adm.DateOut : new Date()
        );
        setDays(days);
        setCaseWorker(adm.CaseWorkerName);
        setPhase(adm.RecentPhase ?? 0);
      }
    } else {
      setDays(null);
    }
    setAdmissions(props?.admissions);
  }, [props]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResident((prevResident) => ({
      ...prevResident,
      [name]: value,
    }));
  };
  const handleInputChangeCW = (e) => {
    const { name, value } = e.target;
    setAdmission((prevAdmission) => ({
      ...prevAdmission,
      [name]: value,
    }));
  };
  const toggleEditMode = (field) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [field]: !prevEditMode[field],
    }));
  };

  const handleSave = async () => {
    // Implement save logic here (e.g., API call to save the changes)
    console.log("Saved", resident);
    try {
      let { data } = await updateResident(resident);
      if (data) {
        console.log(data);
        // setResident(data);
        toast.success("Saved!");
      } else {
        toast.error("Failed to Save Resident");
      }
      // let { data } = await updateResidentFragment(name, resident);
      // if (data) toast.success("Successfully updated");
      // else toast.error("Failed to update");
    } catch (error) {
      //@ts-ignore
      toast.error("Failed to update Fragment");
    }
    setEditMode({ name: false, room: false, caseWorker: false });
  };

  const handleSaveCW = async () => {
    // Implement save logic here (e.g., API call to save the changes)
    console.log("Saved", resident);
    try {
      let { data } = await setResidentAdmissionRecord(admission);
      if (data) {
        toast.info(data.message);
      } else {
        toast.error("Failed to Save Case Worker");
      }
      // let { data } = await updateResidentFragment(name, resident);
      // if (data) toast.success("Successfully updated");
      // else toast.error("Failed to update");
    } catch (error) {
      //@ts-ignore
      toast.error("Failed to update Fragment");
    }
    setEditMode({ name: false, room: false, caseWorker: false });
  };

  function checkSecondPath(url) {
    // Split the string by '/' and filter out empty strings
    const parts = url.split("/").filter((part) => part);
    // Check if the second part is 'resident'
    return parts.length > 1 && parts[1] === "resident";
  }

  useEffect(() => {
    const getandSetUser = async () => {
      try {
        //get Centers
        let centers = await getAllCenters();
        console.log(props.user, "p");
        centers.forEach((center) => {
          if (center.ID === props.user.Center) setUserCenter(center.Name);
        });
      } catch (error) {
        //
      }
    };
    if (props.user.Center) {
      getandSetUser();
    }
  }, [props.user]);

  useEffect(() => {
    async function fetchData() {
      try {
        let { data, error } = await getUsers();
        console.log(data);
        if (error) return setUsers([]);
        data = data.filter(
          (user1) => !user1.isAdmin && !user1.isProductionSupport
        );
        console.log(data);
        setUsers(data);
      } catch (error) {
        setUsers([]);
      }
    }
    fetchData();
  }, []);

  return (
    <div
      className="fixed z-50"
      style={{
        width: "-webkit-fill-available",
      }}
    >
      <div className="topBar-Container">
        <div className=" flex m-3 items-center justify-between p-4 rounded-lg">
          <div className="flex flex-col space-y-0 max-w-[32em]">
            <div className="flex items-center">
              <div className="me-3">
                <i
                  className="fa fa-arrow-left fa-2x primary-text cursor-pointer"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.preventDefault();
                    window.history.back();
                  }}
                ></i>
              </div>
              {editMode.name ? (
                <div className="flex items-center pb-[1.3rem] gap-2 flex-wrap">
                  <input
                    type="text"
                    name="ResFirstName"
                    value={resident.ResFirstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    className="border-b-2 border-gray-300 focus:border-blue-500 outline-none w-[8rem] text-lg"
                  />
                  <input
                    type="text"
                    name="ResMiddleName"
                    placeholder="Middle Name"
                    value={resident.ResMiddleName}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-300 focus:border-blue-500 outline-none w-[8rem] text-lg"
                  />
                  <input
                    type="text"
                    name="ResLastName"
                    placeholder="Last Name"
                    value={resident.ResLastName}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-300 focus:border-blue-500 outline-none w-[8rem] text-lg"
                  />
                  <button
                    onClick={handleSave}
                    className="ml-2 bg-transparent text-green-500"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </button>
                </div>
              ) : (
                <p className="text-[1.8rem] font-bold flex items-center mb-2">
                  {resident.ResFirstName} {resident.ResMiddleName}{" "}
                  {resident.ResLastName}
                  <button
                    onClick={() => toggleEditMode("name")}
                    className="ml-2 text-green-600 bg-transparent"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.232 5.232l3.536 3.536m-6.232 6.232L6.5 21h-3v-3L13.732 8.268a2.5 2.5 0 013.536 0z"
                      ></path>
                    </svg>
                  </button>
                </p>
              )}
            </div>
            {props.resident?.IsActive ? (
              <span className="ms-11 px-2 py-1 text-sm font-medium text-green-700 bg-green-200 rounded-full self-start">
                Active Resident
              </span>
            ) : (
              <span className="ms-11 px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-full self-start">
                Inactive Resident
              </span>
            )}
          </div>
          <div className="flex justify-around items-center space-x-2">
            <div className="text-center min-w-[4.5em]">
              <div className="mr-2">
                <div className="text-sm textBill text-gray-500">
                  Room Number
                </div>
                <div className="text-lg font-semibold">
                  {editMode.room ? (
                    <div className="flex items-center justify-center">
                      <input
                        type="text"
                        name="RoomNum"
                        value={resident.RoomNum}
                        onChange={handleInputChange}
                        className="border-b-2 border-gray-300 focus:border-blue-500 outline-none w-20 text-center"
                      />
                      <button
                        onClick={handleSave}
                        className="ml-2 text-green-600 bg-transparent "
                      >
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <>
                      {resident.RoomNum}
                      <button
                        onClick={() => toggleEditMode("room")}
                        className="ml-2 text-green-600 bg-transparent"
                      >
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15.232 5.232l3.536 3.536m-6.232 6.232L6.5 21h-3v-3L13.732 8.268a2.5 2.5 0 013.536 0z"
                          ></path>
                        </svg>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center w-[3.5em]">
              <div className="text-sm text-gray-500">Phase</div>
              <div className="text-lg font-semibold">{phase}</div>
            </div>
            <div className="text-center min-w-[10.5em]">
              <div className="mr-2">
                <div className="text-sm textBill text-gray-500">
                  Case Worker
                </div>
                <div className="text-lg font-semibold">
                  {admission && editMode.caseWorker ? (
                    <div className="flex items-center justify-center">
                      <select
                        name="CaseWorkerName"
                        value={admission?.CaseWorkerName}
                        onChange={handleInputChangeCW}
                        className="border-b-2 border-gray-300 focus:border-blue-500 outline-none w-32 text-center"
                      >
                        {!users.some(
                          (user) =>
                            user.firstName + " " + user.lastName !=
                            admission?.CaseWorkerName
                        ) && (
                          <option value={admission?.CaseWorkerName}>
                            {admission?.CaseWorkerName}
                          </option>
                        )}
                        {users.map((user) => (
                          <option value={user.firstName + " " + user.lastName}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={handleSaveCW}
                        className="ml-2 text-green-600 bg-transparent"
                      >
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ) : (
                    admission && (
                      <>
                        {admission?.CaseWorkerName}
                        <button
                          onClick={() => toggleEditMode("caseWorker")}
                          className="ml-2 text-green-600 bg-transparent"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-6.232 6.232L6.5 21h-3v-3L13.732 8.268a2.5 2.5 0 013.536 0z"
                            ></path>
                          </svg>
                        </button>
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="text-center w-[4.5em]">
              <div className="mr-2">
                <div className="text-sm text-gray-500">Days in Program</div>
                <div className="text-lg font-semibold">{days}</div>
              </div>
            </div>
            <div className="text-center w-[2em]">
              <div className="text-sm text-gray-500">Stays</div>
              <div className="text-lg font-semibold">
                {admissions?.length ?? 0}
              </div>
            </div>
            {resident.isActive ? (
              <div className="text-center w-[4em]">
                <div className="text-sm text-gray-500">Infraction Points</div>
                <div className="text-lg font-semibold">
                  {resident?.infractions?.reduce(
                    (acc, val) => acc + val.Points,
                    0
                  ) ?? 0}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="min-w-[7vw] max-w-[9vw] flex flex-col items-end">
            <div className="text-end">{`${props.user.firstName} ${props.user.lastName}`}</div>
            <div className="primary-text">
              {props.user.isAdmin
                ? "Admin"
                : props.user.isIntakeCoordinator
                ? "Intake Coordinator"
                : props.user.isCenterCoordinator
                ? "Center Coordinator"
                : props.user.isCaseCoordinator
                ? "Case Coordinator"
                : props.user.isFinance
                ? "Finance"
                : props.user.isHouseManager
                ? "House Manager"
                : "-"}
            </div>
            <div className="text-teal-800 text-end">
              {`${
                props.user.isAdmin
                  ? "Admin Dashboard"
                  : props.user.Center
                  ? userCenter
                  : "New Hope Center"
              }`}
            </div>
            {/* <div className="primary-text">{props.user.email}</div> */}
          </div>
          <div className="ms-3">
            <div className="topBar-Sections-2-Logout pt-2 flex justify-end">
              <Link to="/dashboard/myProfile" className="nav-item">
                <div className="user-profile-box">
                  <i
                    className="fa fa-user fa-2x light-text"
                    aria-hidden="true"
                  ></i>
                </div>
              </Link>
              <Link to="/logout" className="nav-item">
                <div className="topBar-Sections-2-Logout">
                  <i
                    className="fa fa-sign-out fa-2x primary-text paddingLeft1"
                    aria-hidden="true"
                  ></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResTopBar;
