import React from "react";
import Input from "./input";
import CheckBox from "../common/checkbox";
import Date from "../common/date";
import HTMLDate from "../common/htmldate";
import Select from "../common/select";
import YesNo from "./yesNo";
import TextArea from "./textarea";
// import ImageChooser from "./imageChooser";
import AWSImagePicker from "./awsImagePicker";
import Select1 from "./select1";

const InputFieldLayoutRow = (props) => {
  let data = props.data;
  let disabled = props?.disabled;
  const renderInput = (item) => {
    return (
      <Input
        type={item.typeName}
        className={item.className}
        onChange={(json) => props.onChange(json, item.name)}
        name={item.name}
        disabled={disabled}
        label={item.label}
        value={item.value ? item.value : ""}
        showLabel={true}
        error={item.error}
      />
    );
  };

  const renderTextBox = (item) => {
    return (
      <TextArea
        type={item.typeName}
        onChange={(json) => props.onChange(json, item.name)}
        name={item.name}
        disabled={disabled}
        label={item.label}
        value={item.value ? item.value : ""}
        showLabel={true}
        className={item.className}
        error={item.error}
      />
    );
  };

  const renderSelect = (item) => {
    if (item.name.includes("PartnerContactID")) {
      return (
        <Select1
          onChange={(json) => props.onChange(json, item.name)}
          name={item.ContactFirstName}
          label={item.label}
          disabled={disabled}
          options={item.options}
          // options={item.options.map((option) => ({
          //   _id: option._id,
          //   name: option.name,
          // }))}
          value={item.value ? item.value : undefined}
          error={item.error}
          className={item.className}
          noClear={undefined}
        />
      );
    } else
      return (
        <Select
          onChange={(json) => props.onChange(json, item.name)}
          name={item.name}
          label={item.label}
          options={item.options}
          disabled={disabled}
          // options={item.options.map((option) => ({
          //   _id: option._id,
          //   name: option.name,
          // }))}
          className={item.className}
          value={item.value ? item.value : undefined}
          error={item.error}
          noClear={undefined}
        />
      );
  };

  const renderCheckbox = (item) => (
    <CheckBox
      onChange={(json) => props.onChange(json, item.name)}
      name={item.name}
      disabled={disabled}
      label={item.label}
      checked={item.value}
      className={item.className}
      error={item.error}
    />
  );

  const renderYesNo = (item) => {
    console.log(item);
    return (
      <YesNo
        onChange={(json) => props.onChange(json, item.name)}
        name={item.name}
        className={item.className}
        label={item.label}
        disabled={disabled}
        value={item.value}
        error={item.error}
      />
    );
  };
  function isISODateString(dateString) {
    const isoRegex =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
    return isoRegex.test(dateString);
  }
  function isSimpleDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  }
  const renderDate = (item) => {
    console.log("render", item);
    if (
      isISODateString(item.value) ||
      isSimpleDate(item.value) ||
      !item.value
    ) {
      return (
        <HTMLDate
          onChange={(json) => {
            props.onChange(json, item.name);
            console.log(json);
            console.log(item.value);
          }}
          name={item.name}
          disabled={disabled}
          className={item.className}
          label={item.label}
          value={item.value ? item.value.slice(0, 10) : null}
          error={item.error}
        />
      );
    } else {
      return (
        <Date
          onChange={(json) => {
            props.onChange(json, item.name);
            console.log(json);
            disabled = { disabled };
            console.log(item.value);
          }}
          name={item.name}
          label={item.label}
          value={item.value ? item.value : null}
          error={item.error}
        />
      );
    }

    // item.name == "basic_3_1_ResBirthDate" || item.name== ? (

    // ) : (
    //   <Date
    //     onChange={(json) => {
    //       props.onChange(json, item.name);
    //       console.log(json);
    //       console.log(item.value);
    //     }}
    //     name={item.name}
    //     label={item.label}
    //     value={item.value ? item.value : null}
    //     error={item.error}
    //   />
    // );
  };

  const renderImagePicker = (item) => {
    return (
      <AWSImagePicker
        onChange={(data) => props.onChange(data, item.name)}
        name={item.name}
        label={item.label}
        value={item.value ? item.value : undefined}
        url={item.url ? item.url : undefined}
        showLabel={true}
        error={item.error}
      />
    );
  };
  // const renderImagePicker = (item) => {
  //   return (
  //     <ImageChooser
  //       onChange={(url) => props.onChange(url, item.name)}
  //       name={item.name}
  //       label={item.label}
  //       value={item.value ? item.value : undefined}
  //       showLabel={true}
  //       error={item.error}
  //     />
  //   );
  // };

  const renderItem = (item) => {
    switch (item.type) {
      case "input":
        return renderInput(item);
      case "textBox":
        return renderTextBox(item);
      case "select":
        return renderSelect(item);
      case "checkbox":
        return renderCheckbox(item);
      case "date":
        return renderDate(item);
      case "yesNo":
        return renderYesNo(item);
      case "imagePicker":
        return renderImagePicker(item);
      default:
        return renderInput(item);
    }
  };

  return (
    <>
      {data.map((item, i) =>
        !item.hidden && !item.divideRow && !item.divideRow3 ? (
          <div className="createResident-Container-formSection-row">
            <div
              className={
                (i === 0
                  ? "createResident-Container-formSection-rowItem "
                  : i === data.length - 1
                  ? "createResident-Container-formSection-rowItem-end "
                  : "createResident-Container-formSection-rowItem-center ") +
                item.size
              }
              key={i.toString()}
            >
              {renderItem(item)}
            </div>
          </div>
        ) : (
          <></>
        )
      )}
      <div className="grid grid-cols-2 gap-2 items-center">
        {data.map(
          (item, i) => !item.hidden && item.divideRow && renderItem(item)
        )}
      </div>
      <div className="grid grid-cols-3 gap-2 items-center">
        {data.map(
          (item, i) => !item.hidden && item.divideRow3 && renderItem(item)
        )}
      </div>
    </>
  );
};

export default InputFieldLayoutRow;
